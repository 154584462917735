import React, { useState, useEffect } from "react"

import getDisplayName from "../lib/displayName"

import icon from "../images/Icon-Art_of_Science@2x.png"
import logo from "../images/logo-Art_of_science@2x.png"

const Header = ({ web3 }) => {
  const [menuOpen, setMenuOpen] = useState(false)

  const { onboard, prepareToTransact } = web3
  const displayName = getDisplayName(web3)

  const hideSearchOnDesktop = () => {
    if (menuOpen) setMenuOpen(false)
    matchMedia("(min-width: 1024px)").addEventListener(
      "change",
      hideSearchOnDesktop,
      { once: true }
    )
  }

  useEffect(() => {
    hideSearchOnDesktop()
  }, [])

  const MobileMenuLink = ({ children, ...props }) => (
    <p className="border-b border-primary border-opacity-50 p-6">
      <a onClick={() => setMenuOpen(false)} {...props}>
        {children}
      </a>
    </p>
  )

  return (
    <div className="flex justify-between items-center mt-14">
      <div className="flex items-center">
        <img src={icon} className="w-14 xl:w-28 mr-4 sm:mr-7" />
        <img src={logo} className="w-40 sm:w-52 xl:w-[320px]" />
      </div>

      <div className="hidden xl:inline-flex font-medium text-primary xl:text-lg uppercase tracking-[.3125em]">
        <p className="mr-6">
          <a href="#chipart" className="hover:opacity-80">
            Chipart
          </a>
        </p>
        <p className="mr-6">
          <a href="#about" className="hover:opacity-80">
            About
          </a>
        </p>
        <p className="mr-6">
          <a href="#roadmap" className="hover:opacity-80">
            Roadmap
          </a>
        </p>
        <p className="mr-6">
          <a href="#team" className="hover:opacity-80">
            Team
          </a>
        </p>

        {displayName ? (
          <button
            className="hover:opacity-80 tracking-[.3125em]"
            onClick={() => onboard.walletReset()}
          >
            {displayName}
          </button>
        ) : (
          <p>
            <button
              onClick={prepareToTransact}
              className="hover:opacity-80 tracking-[.3125em]"
            >
              Connect
            </button>
          </p>
        )}
      </div>

      <div className="xl:hidden mt-2">
        <Hamburger onClick={() => setMenuOpen(true)} />

        {menuOpen && (
          <div className="fixed top-0 right-0 bottom-0 max-w-xs w-3/4 sm:w-1/2 bg-purple text-primary tracking-widest z-50">
            <div className="flex justify-end mt-4 mr-4 mb-6">
              <button
                onClick={() => setMenuOpen(false)}
                className="tracking-widest"
              >
                &#x2715; Close
              </button>
            </div>

            <MobileMenuLink href="#chipart">Chipart</MobileMenuLink>
            <MobileMenuLink href="#about">About</MobileMenuLink>
            <MobileMenuLink href="#roadmap">Roadmap</MobileMenuLink>
            <MobileMenuLink href="#team">Team</MobileMenuLink>
            <p className="border-b border-primary border-opacity-50 p-6 tracking-widest">
              <button
                onClick={() => {
                  setMenuOpen(false)
                  prepareToTransact()
                }}
              >
                Connect
              </button>
            </p>
          </div>
        )}
      </div>
    </div>
  )
}

export default Header

const Hamburger = ({ onClick }) => (
  <button className="w-7" onClick={onClick}>
    <div className="w-full bg-primary h-0.5 mb-1">&nbsp;</div>
    <div className="w-full bg-primary h-0.5 mb-1">&nbsp;</div>
    <div className="w-full bg-primary h-0.5">&nbsp;</div>
  </button>
)
