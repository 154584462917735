import React from "react"
import cx from "classnames"

export default function FancyDivider({ className }) {
  return (
    <div className={cx("w-auto -mx-7 sm:-mx-5 lg:-mx-32", className)}>
      <div className="gradient-divider w-full h-60"></div>
      <div className="bg-black h-1">&nbsp;</div>
      <div className=" h-0.5 mt-px bg-white bg-opacity-10">&nbsp;</div>
    </div>
  )
}
