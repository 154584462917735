import React from "react"

import useMintState from "../lib/useMintState"
import PreMint from "./MintModule/PreMint"
import Mint from "./MintModule/Mint"

import "../styles/mint.css"

export default function MintModule(props) {
  const mintState = useMintState(props.web3.whitelisted)

  switch (mintState.state) {
    case "premint":
      return <PreMint {...props} mintState={mintState} />
    case "presale":
    case "mint":
      return <Mint {...props} mintState={mintState} />
    default:
      return null
  }
}
