import React from "react"
import Socials from "../Socials"

export default function PreMint({ mintState }) {
  const { presaleEta, mintEta } = mintState
  const eta = presaleEta === null ? mintEta : presaleEta

  return (
    <div className="mt-8 sm:mt-14">
      <Socials />

      <h2 className="font-bold text-primary uppercase text-4xl mt-28 tracking-wider">
        {presaleEta === null ? "Public Sale" : "First Drop"}
      </h2>

      <div className="font-bold text-3xl sm:text-4xl text-primary text-center flex gap-8 sm:gap-10 mt-3 leading-normal">
        <div>
          <p>{eta.days}</p>
          <p className="font-mono text-sm opacity-80">days</p>
        </div>
        <div>
          <p>{eta.hours}</p>
          <p className="font-mono text-sm opacity-80">hours</p>
        </div>
        <div>
          <p>{eta.minutes}</p>
          <p className="font-mono text-sm opacity-80">minutes</p>
        </div>
        <div>
          <p>{eta.seconds}</p>
          <p className="font-mono text-sm opacity-80">seconds</p>
        </div>
      </div>
    </div>
  )
}
