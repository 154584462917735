import React from "react"

import grid from "../images/grid@2x.png"
import siliconinsider from "../images/Profile-Siliconinsider.png"
import divergence from "../images/Profile-Divergence.png"
import mullet from "../images/Profile-Mullet.png"
import meltingice from "../images/Profile-MeltingIce.png"
import michiel from "../images/Profile-Michiel_Andrea.png"
import aernoud from "../images/Profile-Aernoud_Bourdrez.png"
import twitterIcon from "../images/icon-Twitter.png"

export default function AboutTheTeam() {
  return (
    <div id="team" className="max-w-screen-xl mx-auto w-full">
      <div className="relative max-w-[580px] lg:max-w-[660px]">
        <div className="absolute top-0 left-0 -mt-6 sm:-mt-12 ml-28 flex -z-10">
          <img src={grid} className="w-[306px] h-[306px]" />
        </div>

        <div className="gradient-big-green opacity-[.19] absolute top-0 left-0 -z-20 w-[1316px] h-[1316px] ml-[-300px] mt-[-550px]" />
        <div className="gradient-medium-pink opacity-[.27] absolute top-0 left-0 -z-20 w-[1000px] h-[1000px] ml-[30px] mt-[-350px]" />

        <h2 className="text-5xl sm:text-7xl lg:text-8xl font-bold text-primary uppercase leading-none mb-8">
          Team
        </h2>
      </div>

      <div className="grid md:grid-cols-2 xl:grid-cols-3 gap-20 mt-32 md:mt-48">
        <TeamMember
          name="Siliconinsider"
          image={siliconinsider}
          twitter="siliconinsid"
        >
          Professional micro paleontologist by day, computer historian and
          collector by night: connecting two universes with a deep passion for
          microscopy and revealing invisible treasures from our past and current
          world.
        </TeamMember>

        <TeamMember
          name="Divergence Team"
          image={divergence}
          twitter="divergencearran"
        >
          Two tech nerds who enjoy pushing Solidity boundaries, artistically and
          otherwise. Collections / collabs include PROOF OF &#123;ART&#125;WORK,
          Brotchain, The Kiss, Every Icon and Proof Collective.
        </TeamMember>

        <TeamMember name="Mullet" image={mullet} twitter="Mulletize">
          Entrepreneur and NFT collector focused on building Web3 communities
          spanning watch, blockchain, and tech.
        </TeamMember>

        <TeamMember name="MeltingIce" image={meltingice} twitter="meltingice">
          Principal engineer and full-stack web developer. Food, car, and watch
          enthusiast.
        </TeamMember>

        <TeamMember
          name="Michiel Andrea"
          image={michiel}
          twitter="michielandrea"
        >
          Graphic designer from the Netherlands. Astronaut without a diploma.
          Beer enthusiast.
        </TeamMember>

        <TeamMember name="Aernoud Bourdrez" image={aernoud} twitter="aernoudb">
          IP-attorney possessing a close-to-obsessive fascination with emerging
          art.
        </TeamMember>
      </div>
    </div>
  )
}

const TeamMember = ({ name, image, twitter, children }) => (
  <div className="flex flex-col bg-purple border border-primary max-w-[280px] sm:max-w-[360px] mx-auto">
    <div className="flex items-center gap-2 py-2 px-4 border-b border-primary">
      <div className="flex-1 h-2 border-t border-b border-primary">&nbsp;</div>
      <div className="font-mono text-sm text-primary">{name}</div>
      <div className="flex-1 h-2 border-t border-b border-primary">&nbsp;</div>
    </div>

    <div className="flex-1">
      <img src={image} className="w-full border-b border-primary" />
      <div className="px-5 pt-5">
        <p className="font-mono text-xs sm:text-sm text-primary whitespace-pre-wrap mb-4">
          {children}
        </p>
      </div>
    </div>

    <div className="p-5">
      <div className="flex items-center">
        <img src={twitterIcon} className="h-3 sm:h-4 mr-2" />{" "}
        <a
          href={`https://twitter.com/${twitter}`}
          className="font-mono text-xs sm:text-sm text-primary underline"
          target="_blank"
        >
          {twitter}
        </a>
      </div>
    </div>
  </div>
)
