import React, { useState, useEffect } from "react"
import cx from "classnames"
import times from "lodash/times"

import useMintable from "../../lib/useMintable"
import constants from "../../lib/constants"
import Socials from "../../components/Socials"
import PostMint from "./PostMint"
import { ReactComponent as IconArrow } from "../../images/icon-arrow.svg"
const { explorerUrl } = constants

export default function Mint({ web3, mintState }) {
  const { inventoryRemaining, totalInventory, mint, maxPerTx } = useMintable(
    web3,
    mintState.state
  )
  const { prepareToTransact, readyToTransact } = web3
  const [mintingState, setMintingState] = useState("idle")
  const [mintQuantity, setMintQuantity] = useState(1)
  const [mintTx, setMintTx] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    if (mintingState === "connecting" && readyToTransact) {
      onMint()
    }
  }, [mintingState, readyToTransact])

  const onMint = async () => {
    if (!readyToTransact) {
      setMintingState("connecting")
      prepareToTransact()
      return
    }

    setError(null)
    setMintingState("minting")

    try {
      const tx = await mint(mintQuantity)
      setMintTx(tx.hash)

      const receipt = await tx.wait()

      setMintingState(receipt.status === 1 ? "success" : "error")
    } catch (e) {
      setMintingState("error")

      switch (e.code) {
        case "INSUFFICIENT_FUNDS":
          setError("Insufficient funds for this transaction.")
          return
        default:
          setError(e.error.message)
      }
    }
  }

  if (inventoryRemaining === 0) return <PostMint />

  return (
    <>
      <div className="sm:flex gap-x-12 items-center mt-8 sm:mt-14">
        <div className="flex-shrink-0">
          <MintButton
            onClick={onMint}
            mintState={mintState}
            mintingState={mintingState}
            mintQuantity={mintQuantity}
          />

          <div className="mt-4">
            <label className="block font-mono text-xs text-primary mb-1">
              Number to mint
            </label>
            <select
              className="w-full bg-primary p-2 font-mono text-sm max-w-[263px] sm:max-w-none"
              onChange={e => setMintQuantity(parseInt(e.target.value, 10))}
            >
              {times(mintState.state === "mint" ? maxPerTx : 2, i => (
                <option key={i} value={i + 1}>
                  {i + 1}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="mt-14 sm:mt-0">
          <Socials />
        </div>
      </div>

      <div className="mt-6">
        {inventoryRemaining && (
          <p className="font-mono text-xs sm:text-sm xl:text-base text-primary">
            {inventoryRemaining.toLocaleString()} /{" "}
            {totalInventory.toLocaleString()} remaining
          </p>
        )}

        {error && (
          <p className="font-mono text-xs sm:text-sm xl:text-base text-primary italic">
            Error: {error}
          </p>
        )}

        {mintTx && (
          <p>
            <a
              className="font-mono text-xs sm:text-sm xl:text-base text-primary underline"
              href={`${explorerUrl}/tx/${mintTx}`}
              target="_blank"
            >
              View mint transaction
            </a>
          </p>
        )}
      </div>
    </>
  )
}

const MintButton = ({ onClick, mintState, mintingState, mintQuantity }) => {
  let buttonText
  if (mintingState === "idle")
    buttonText = mintState.state === "presale" ? "Pre-Sale" : "Mint"
  else if (mintingState === "connecting") buttonText = "Connecting"
  else if (mintingState === "minting") buttonText = "Minting"
  else if (mintingState === "success") buttonText = "Mint Successful"
  else buttonText = "Mint Error"

  return (
    <button
      className={cx(
        "bg-primary p-6 text-[#13061D] font-mono text-xs sm:text-sm hover:opacity-80  transition-opacity duration-200 ease-in-out",
        {
          "btn-loading": mintingState === "minting",
          "btn-success": mintingState === "success",
        }
      )}
      onClick={onClick}
      disabled={mintingState === "minting" || mintingState === "success"}
    >
      {buttonText} &ndash;{" "}
      <span className="italic">
        ETH {Math.round(0.18 * 100 * mintQuantity) / 100}
      </span>{" "}
      <IconArrow className="w-5 inline ml-20" />
    </button>
  )
}
