import { BigNumber, utils } from "ethers"
import { useState, useEffect } from "react"

export default function useMintable(web3, mintState) {
  const { contract, address, signature } = web3

  const config = { totalInventory: 2750, maxPerTx: 10, cost: 0.18 }
  const [stats, setStats] = useState({
    inventoryRemaining: null,
    totalSold: null,
  })

  const fetchStats = async () => {
    try {
      const totalSold = await contract.totalSold()

      setStats({
        totalSold: totalSold.toNumber(),
        inventoryRemaining: config.totalInventory - totalSold.toNumber(),
      })
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    let interval
    if (web3.address) {
      interval = setInterval(fetchStats, 2000)
    }

    return () => clearInterval(interval)
  }, [web3.address])

  const mint = (n = 1) => {
    const cost = (Math.round(0.18 * 100 * n) / 100).toString()
    if (mintState === "presale") {
      return contract.mintEarly(address, BigNumber.from(n), signature, {
        value: utils.parseUnits(cost),
      })
    } else {
      return contract.mint(address, BigNumber.from(n), {
        value: utils.parseUnits(cost),
      })
    }
  }

  return {
    mint,
    ...config,
    ...stats,
  }
}
