// Originals
import chip1 from "../images/chip_carousel/0665.jpg"
import chip2 from "../images/chip_carousel/1689.jpg"
import chip3 from "../images/chip_carousel/2101.jpg"
import chip4 from "../images/chip_carousel/2378.jpg"
import chip5 from "../images/chip_carousel/2079.jpg"
import chip6 from "../images/chip_carousel/0809.jpg"
import chip7 from "../images/chip_carousel/1760.jpg"
import chip8 from "../images/chip_carousel/2478.jpg"
import chip9 from "../images/chip_carousel/2581.jpg"
import chip10 from "../images/chip_carousel/2743.jpg"
import chip11 from "../images/chip_carousel/0700.jpg"

import chip12 from "../images/chip_carousel/1652.jpg"
import chip13 from "../images/chip_carousel/1493.jpg"
import chip14 from "../images/chip_carousel/2020.jpg"
import chip15 from "../images/chip_carousel/2467.jpg"
import chip16 from "../images/chip_carousel/2185.jpg"
import chip17 from "../images/chip_carousel/1367.jpg"
import chip18 from "../images/chip_carousel/1628.jpg"
import chip19 from "../images/chip_carousel/0315.jpg"
import chip20 from "../images/chip_carousel/1814.jpg"
import chip21 from "../images/chip_carousel/2407.jpg"
import chip22 from "../images/chip_carousel/2616.jpg"

// Previews
import chip1Preview from "../images/chip_carousel/previews/0665.jpeg"
import chip2Preview from "../images/chip_carousel/previews/1689.jpeg"
import chip3Preview from "../images/chip_carousel/previews/2101.jpeg"
import chip4Preview from "../images/chip_carousel/previews/2378.jpeg"
import chip5Preview from "../images/chip_carousel/previews/2079.jpeg"
import chip6Preview from "../images/chip_carousel/previews/0809.jpeg"
import chip7Preview from "../images/chip_carousel/previews/1760.jpeg"
import chip8Preview from "../images/chip_carousel/previews/2478.jpeg"
import chip9Preview from "../images/chip_carousel/previews/2581.jpeg"
import chip10Preview from "../images/chip_carousel/previews/2743.jpeg"
import chip11Preview from "../images/chip_carousel/previews/0700.jpeg"

import chip12Preview from "../images/chip_carousel/previews/1652.jpeg"
import chip13Preview from "../images/chip_carousel/previews/1493.jpeg"
import chip14Preview from "../images/chip_carousel/previews/2020.jpeg"
import chip15Preview from "../images/chip_carousel/previews/2467.jpeg"
import chip16Preview from "../images/chip_carousel/previews/2185.jpeg"
import chip17Preview from "../images/chip_carousel/previews/1367.jpeg"
import chip18Preview from "../images/chip_carousel/previews/1628.jpeg"
import chip19Preview from "../images/chip_carousel/previews/0315.jpeg"
import chip20Preview from "../images/chip_carousel/previews/1814.jpeg"
import chip21Preview from "../images/chip_carousel/previews/2407.jpeg"
import chip22Preview from "../images/chip_carousel/previews/2616.jpeg"

export const CAROUSEL_1 = [
  {
    title: "LH9124",
    image: chip1,
    preview: chip1Preview,
  },
  {
    title: "In pixels we trust",
    image: chip2,
    preview: chip2Preview,
  },
  {
    title: "MC68040V",
    image: chip3,
    preview: chip3Preview,
  },
  {
    title: "S1289L43",
    image: chip4,
    preview: chip4Preview,
  },
  {
    title: "HyperSPARC 256k module",
    image: chip5,
    preview: chip5Preview,
  },
  {
    title: "PD65782",
    image: chip6,
    preview: chip6Preview,
  },
  {
    title: "IF700AA",
    image: chip7,
    preview: chip7Preview,
  },
  {
    title: "IR2233",
    image: chip8,
    preview: chip8Preview,
  },
  {
    title: "ADSP21061",
    image: chip9,
    preview: chip9Preview,
  },
  {
    title: "DS1633",
    image: chip10,
    preview: chip10Preview,
  },
  {
    title: "MB91F005A",
    image: chip11,
    preview: chip11Preview,
  },
]

export const CAROUSEL_2 = [
  {
    title: "SN104961",
    image: chip12,
    preview: chip12Preview,
  },
  {
    title: "DC1024A",
    image: chip13,
    preview: chip13Preview,
  },
  {
    title: "ADSP2184",
    image: chip14,
    preview: chip14Preview,
  },
  {
    title: "PCI7412",
    image: chip15,
    preview: chip15Preview,
  },
  {
    title: "79RV4700",
    image: chip16,
    preview: chip16Preview,
  },
  {
    title: "PNX1701EH",
    image: chip17,
    preview: chip17Preview,
  },
  {
    title: "RM5230",
    image: chip18,
    preview: chip18Preview,
  },
  {
    title: "1AA7",
    image: chip19,
    preview: chip19Preview,
  },
  {
    title: "MT1358",
    image: chip20,
    preview: chip20Preview,
  },
  {
    title: "TMS320C80C",
    image: chip21,
    preview: chip21Preview,
  },
  {
    title: "ES4138F",
    image: chip22,
    preview: chip22Preview,
  },
]
