import React from "react"

export default function GradientHeading({ children }) {
  return (
    <div className="relative">
      <div className="gradient-medium-pink opacity-[.27] absolute top-0 left-0 -z-20 w-[1000px] h-[1000px] ml-[-250px] mt-[-320px]" />
      <div className="gradient-big-green opacity-[.25] absolute top-0 left-0 -z-20 w-[1852px] h-[1852px] ml-[-400px] mt-[-800px]" />

      <h2 className="text-4xl sm:text-7xl lg:text-8xl font-bold text-primary uppercase leading-none mb-8">
        {children}
      </h2>
    </div>
  )
}
