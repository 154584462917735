import React from "react"
import Socials from "../Socials"

export default function PostMint() {
  return (
    <div className="mt-8 sm:mt-14">
      <Socials />

      <h2 className="font-bold text-primary uppercase text-4xl mt-28 tracking-wider">
        Sold Out
      </h2>

      <p className="font-mono text-primary mt-3">
        All pieces have been minted. Check out the collection on{" "}
        <a href="https://opensea.io/collection/chipart" className="underline">
          OpenSea
        </a>
        .
      </p>
    </div>
  )
}
