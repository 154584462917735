import React from "react"

import iconChip from "../images/icon-chip.png"
import iconArt from "../images/icon-Art_curation.png"
import iconScience from "../images/icon-Support_Science.png"

export default function NFTCollectionBreaker() {
  return (
    <div className="lg:flex gap-20 text-center sm:text-left relative max-w-screen-xl mx-auto">
      <div className="gradient-medium-blue opacity-[.22] absolute top-0 left-0 -ml-[303px] -mt-[300px] w-[1000px] h-[1000px]" />
      <div className="gradient-medium-purple opacity-[.22] absolute top-0 left-0 ml-[336px] -mt-[300px] w-[1000px] h-[1000px]" />
      <div className="gradient-medium-pink opacity-[.22] absolute top-0 left-0 ml-[960px] -mt-[300px] w-[1000px] h-[1000px]" />

      <div className="flex-1 sm:flex lg:block items-center mb-10 lg:mb-0">
        <ImageWithMask src={iconChip} className="w-[70px]" />

        <div className="mt-10 sm:mt-0 lg:mt-10">
          <p className="font-bold tracking-widest text-2xl text-primary uppercase mb-4 lg:mb-8">
            NFT Collection
          </p>
          <p className="font-mono text-xs sm:text-sm lg:text-base text-primary">
            Each NFT is minted on the Ethereum blockchain as an ERC-721 token.
            Every NFT is unique and has its own impactful history in the
            advancement of scientific research.
          </p>
        </div>
      </div>

      <div className="flex-1 sm:flex lg:block items-center mb-10 lg:mb-0">
        <ImageWithMask src={iconArt} className="w-[80px]" />

        <div className="mt-10 sm:mt-0 lg:mt-10">
          <p className="font-bold tracking-widest text-2xl text-primary uppercase mb-4 lg:mb-8">
            Art Curation
          </p>
          <p className="font-mono text-xs sm:text-sm lg:text-base text-primary">
            Together with researchers, scientists, and art curators, we
            carefully examine each project in order to deliver beautiful pieces
            of art that boast a meaningful history.
          </p>
        </div>
      </div>

      <div className="flex-1 sm:flex lg:block items-center">
        <ImageWithMask src={iconScience} className="w-[40px]" />

        <div className="mt-10 sm:mt-0 lg:mt-10">
          <p className="font-bold tracking-widest text-2xl text-primary uppercase mb-4 lg:mb-8">
            Support Science
          </p>
          <p className="font-mono text-xs sm:text-sm lg:text-base text-primary">
            Art of Science will dedicate a portion of the proceeds to support
            scientists, researchers, and/or universities that helped create the
            artwork or are linked to the project.
          </p>
        </div>
      </div>
    </div>
  )
}

const ImageWithMask = ({ src, className }) => (
  <div className="flex-shrink-0 rounded-full border-4 border-primary flex items-center justify-center w-[140px] h-[140px] mx-auto sm:mr-10 lg:mx-0 overflow-hidden bg-[#581759] bg-opacity-[.34]">
    <img src={src} className={className} />
  </div>
)
