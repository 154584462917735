import { useState, useEffect } from "react"

import constants from "./constants"
const { presaleDate, mintDate } = constants

const PRESALE_TIMESTAMP = new Date(presaleDate).getTime()
const MINT_TIMESTAMP = new Date(mintDate).getTime()
const calculateEta = timestamp => {
  const now = new Date().getTime()
  const eta = Math.floor((timestamp - now) / 1000)

  if (eta <= 0) return null

  return {
    days: Math.floor(eta / 60 / 60 / 24),
    hours: Math.floor(eta / 60 / 60) % 24,
    minutes: Math.floor(eta / 60) % 60,
    seconds: eta % 60,
  }
}

export default function useMintState(whitelisted) {
  // If mintEta is null, then both presale and minting start have passed.
  // If presaleEta is null but mintEta is not, then we are in presale.
  // Otherwise, we're waiting on presale to start.
  const [mintState, setMintState] = useState({})

  const calculateMintState = () => {
    const presaleEta = calculateEta(PRESALE_TIMESTAMP)
    const mintEta = calculateEta(MINT_TIMESTAMP)

    const state =
      mintEta === null
        ? "mint"
        : presaleEta === null && whitelisted
        ? "presale"
        : "premint"

    return {
      state,
      presaleEta,
      mintEta,
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      const currentState = calculateMintState()
      setMintState(currentState)

      // All countdowns have finished, kill the interval
      if (mintState.state === "mint") {
        clearInterval(interval)
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [whitelisted])

  useEffect(() => {
    setMintState(calculateMintState())
  }, [whitelisted])

  return mintState
}
