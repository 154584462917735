import React from "react"

import constants from "../lib/constants"

import openSeaIcon from "../images/icon-Opensea.png"
import twitterIcon from "../images/icon-Twitter.png"
import discordIcon from "../images/icon-Discord.png"
import etherscanIcon from "../images/icon-Etherscan.png"

export default function Socials() {
  const { explorerUrl, contractAddr } = constants
  return (
    <div className="flex-1 flex items-center">
      <a
        href="https://opensea.io/collection/chipart"
        className="mr-8"
        target="_blank"
      >
        <img src={openSeaIcon} className="h-8" />
      </a>
      <a
        href="https://twitter.com/artof_science"
        className="mr-8"
        target="_blank"
      >
        <img src={twitterIcon} className="h-8" />
      </a>
      <a
        href="https://discord.gg/artofscience"
        className="mr-8"
        target="_blank"
      >
        <img src={discordIcon} className="h-8" />
      </a>
      <a
        href={`${explorerUrl}/address/${contractAddr}`}
        className="mr-8"
        target="_blank"
      >
        <img src={etherscanIcon} className="h-10" />
      </a>
    </div>
  )
}
