import React from "react"

import pointerRight from "../images/Pointer.png"
import iconCharity from "../images/icon-Charity.png"
import iconChipReference from "../images/icon-Chip Reference Reveal.png"
import iconChipArt from "../images/icon-Chip Art Reveal.png"
import iconAirdrop from "../images/icon-Airdrop.png"
import iconCommunity from "../images/icon-Community.png"
import iconFullRes from "../images/icon-Full Resolution Images.png"
import grid from "../images/grid@2x.png"
import pointer11 from "../images/Pointer roadmap 1-1.png"
import pointer12 from "../images/Pointer roadmap 1-2.png"
import pointer13 from "../images/Pointer roadmap 1-3.png"
import pointer14 from "../images/Pointer roadmap 1-4.png"
import pointer21 from "../images/Pointer roadmap 2-1.png"
import pointer22 from "../images/Pointer roadmap 2-2.png"
import pointer23 from "../images/Pointer roadmap 2-3.png"
import pointerMobile from "../images/Pointer-20.png"

export default function Roadmap() {
  return (
    <div id="roadmap" className="max-w-screen-xl mx-auto w-full">
      <div className="relative">
        <div className="absolute top-0 left-0 -ml-12 sm:-ml-44 -mt-12 flex -z-10">
          <img src={grid} className="w-[306px] h-[306px]" />
        </div>

        <div className="gradient-medium-pink opacity-[.27] absolute top-0 left-0 -z-10 w-[1000px] h-[1000px] ml-[-161px] mt-[-250px]" />
        <div className="gradient-big-yellow opacity-[.21] absolute top-0 left-0 -z-10 w-[1852px] h-[1852px] ml-[-277px] mt-[-750px]" />

        <h2 className="text-5xl sm:text-7xl lg:text-8xl font-bold text-primary uppercase leading-none mb-8">
          Roadmap
        </h2>
      </div>

      <div className="sm:flex gap-16 mt-24 sm:mt-44">
        <div className="flex-1 flex justify-center sm:justify-end relative">
          <div className="absolute -z-10 gradient-medium-rainbow-2 opacity-[.22] w-[1000px] h-[1000px] -top-[350px] -left-[350px] sm:left-0" />
          <ImageWithMask src={iconChipArt} className="w-[44px]" />
        </div>
        <div className="flex-1 mt-8 sm:mt-0">
          <p className="font-bold text-primary text-3xl text-center sm:text-left uppercase tracking-widest mb-4">
            Art Reveal
          </p>
          <p className="font-mono text-primary text-xs sm:text-sm xl:text-base text-center sm:text-left max-w-[620px]">
            The artwork will be revealed on March 11, 2022 5:00pm EST.
            <br />
            Pre-mint: March 9, 2022 5:00pm EST
            <br />
            Public mint: March 10, 2022 5:00pm EST
          </p>
        </div>
      </div>

      {/* Start Charity */}
      <div className="sm:flex mt-12 sm:mt-20 gap-16">
        <div className="flex-1 flex justify-center sm:justify-end">
          <ImageWithMask src={iconCharity} className="w-[76px] sm:w-[56px]" />
        </div>
        <div className="flex-1 mt-8 sm:mt-0">
          <p className="font-bold text-primary text-3xl text-center sm:text-left uppercase tracking-widest mb-4">
            Charity
          </p>
          <p className="font-mono text-primary text-xs sm:text-sm xl:text-base text-center sm:text-left max-w-[620px]">
            As soon as the project sells out, we will send ten percent of
            initial sales to the following four charities that work to preserve
            computer history:
          </p>

          <div className="relative flex flex-col-reverse sm:block font-mono text-sm xl:text-base text-primary mt-5 -mx-7 sm:mx-0">
            <div className="sm:absolute top-0 left-0 flex items-center sm:items-end">
              <img src={pointer11} className="hidden sm:block" />
              <img src={pointerMobile} className="sm:hidden -rotate-90" />
              <p className="ml-7">
                <a
                  href="http://www.computinghistory.org.uk/"
                  className="underline"
                  target="_blank"
                >
                  The Centre for Computing History
                </a>
              </p>
            </div>

            <div className="sm:absolute top-0 left-0 flex items-center sm:items-end sm:ml-12">
              <img src={pointer12} className="hidden sm:block" />
              <img src={pointerMobile} className="sm:hidden -rotate-90" />
              <p className="ml-7">
                <a href="https://mo5.com" className="underline" target="_blank">
                  M05.com
                </a>
              </p>
            </div>

            <div className="sm:absolute top-0 left-0 flex items-center sm:items-end sm:ml-24">
              <img src={pointer13} className="hidden sm:block" />
              <img src={pointerMobile} className="sm:hidden -rotate-90" />
              <p className="ml-7">
                <a
                  href="https://www.cpushack.com"
                  className="underline"
                  target="_blank"
                >
                  The CPU Shack
                </a>
              </p>
            </div>

            <div className="sm:absolute top-0 left-0 flex items-center sm:items-end sm:ml-36">
              <img src={pointer14} className="hidden sm:block" />
              <img src={pointerMobile} className="sm:hidden -rotate-90" />
              <p className="ml-7">
                <a
                  href="https://girlswhocode.com"
                  className="underline"
                  target="_blank"
                >
                  Girls Who Code
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* End Charity */}

      {/* Start Chip Info Reveal */}
      <div className="sm:flex gap-16 mt-12 sm:mt-[400px]">
        <div className="flex-1 flex justify-center sm:justify-end relative">
          <div className="absolute -z-10 gradient-medium-rainbow-1 opacity-[.22] w-[1000px] h-[1000px] -top-[350px] -left-[350px] sm:left-0" />
          <ImageWithMask src={iconChipReference} className="w-[54px]" />
        </div>
        <div className="flex-1 mt-8 sm:mt-0">
          <p className="font-bold text-primary text-3xl text-center sm:text-left uppercase tracking-widest mb-4">
            Chip Info Reveal
          </p>
          <p className="font-mono text-primary text-xs sm:text-sm xl:text-base text-center sm:text-left max-w-[620px]">
            Post-reveal, we will start adding more NFT properties to Opensea,
            giving hardcore geeks a few days to scour the internet and research
            chip topography prior to the chip art reveal.
          </p>

          <div className="relative flex flex-col-reverse sm:block font-mono text-sm xl:text-base text-primary mt-5 -mx-7 sm:mx-0">
            <div className="sm:absolute top-0 left-0 flex items-center sm:items-end">
              <img src={pointer21} className="hidden sm:block" />
              <img src={pointerMobile} className="sm:hidden -rotate-90" />
              <p className="ml-7">
                March 14th: Architecture and function reveal
              </p>
            </div>

            <div className="sm:absolute top-0 left-0 flex items-center sm:items-end sm:ml-12">
              <img src={pointer22} className="hidden sm:block" />
              <img src={pointerMobile} className="sm:hidden -rotate-90" />
              <p className="ml-7">March 13th: Part number reveal</p>
            </div>

            <div className="sm:absolute top-0 left-0 flex items-center sm:items-end sm:ml-24">
              <img src={pointer23} className="hidden sm:block" />
              <img src={pointerMobile} className="sm:hidden -rotate-90" />
              <p className="ml-7">March 12th: Chip manufacturer reveal</p>
            </div>
          </div>
        </div>
      </div>
      {/* End Chip Info Reveal */}

      <div className="sm:flex gap-16 mt-12 sm:mt-[274px]">
        <div className="flex-1 flex justify-center sm:justify-end relative">
          <div className="absolute -z-10 gradient-medium-rainbow-2 opacity-[.22] w-[1000px] h-[1000px] -top-[350px] -left-[350px] sm:left-0" />
          <ImageWithMask src={iconChipArt} className="w-[44px]" />
        </div>
        <div className="flex-1 mt-8 sm:mt-0">
          <p className="font-bold text-primary text-3xl text-center sm:text-left uppercase tracking-widest mb-4">
            Chip Art Reveal
          </p>
          <p className="font-mono text-primary text-xs sm:text-sm xl:text-base text-center sm:text-left max-w-[620px]">
            One day after all chip info is revealed, we will announce which
            silicon chips in fact contain the rare chip art. The community will
            then enjoy some time to locate said art and trade/buy their favorite
            chip art NFTs on the secondary market.
          </p>
        </div>
      </div>

      <div className="sm:flex gap-16 mt-12 sm:mt-20">
        <div className="flex-1 flex justify-center sm:justify-end relative">
          <div className="absolute -z-10 gradient-medium-rainbow-3 opacity-[.22] w-[1000px] h-[1000px] -top-[350px] -left-[350px] sm:left-0" />
          <ImageWithMask src={iconAirdrop} className="w-[34px]" />
        </div>
        <div className="flex-1 mt-8 sm:mt-0">
          <p className="font-bold text-primary text-3xl text-center sm:text-left uppercase tracking-widest mb-4">
            Airdrop
          </p>
          <p className="font-mono text-primary text-xs sm:text-sm xl:text-base text-center sm:text-left max-w-[620px]">
            After revealing the lucky owners of the NFTs that contain chip art,
            we will prepare an airdrop with a chip art NFT. This NFT can either
            be used as a free mint pass for the second Art of Science drop or as
            different currency; we will let the community decide.
          </p>
        </div>
      </div>

      <div className="sm:flex gap-16 mt-12 sm:mt-20">
        <div className="flex-1 flex justify-center sm:justify-end relative">
          <div className="absolute -z-10 gradient-medium-rainbow-4 opacity-[.22] w-[1000px] h-[1000px] -top-[350px] -left-[350px] sm:left-0" />
          <ImageWithMask src={iconCommunity} className="w-[50px]" />
        </div>
        <div className="flex-1 mt-8 sm:mt-0">
          <p className="font-bold text-primary text-3xl text-center sm:text-left uppercase tracking-widest mb-4">
            Community
          </p>
          <p className="font-mono text-primary text-xs sm:text-sm xl:text-base text-center sm:text-left max-w-[620px]">
            Next to a dedicated Discord channel, Art of Science will build a
            reference website containing detailed information about the chips.
            Wherever possible, we will then update this platform with the
            experiences of the designers behind the chip art and look forward to
            sharing their unique stories.
          </p>
        </div>
      </div>

      <div className="sm:flex gap-16 mt-12 sm:mt-20">
        <div className="flex-1 flex justify-center sm:justify-end relative">
          <div className="absolute -z-10 gradient-medium-rainbow-5 opacity-[.22] w-[1000px] h-[1000px] -top-[350px] -left-[350px] sm:left-0" />
          <ImageWithMask src={iconFullRes} className="w-[42px]" />
        </div>
        <div className="flex-1 mt-8 sm:mt-0">
          <p className="font-bold text-primary text-3xl text-center sm:text-left uppercase tracking-widest mb-4">
            Full-Resolution Images
          </p>
          <p className="font-mono text-primary text-xs sm:text-sm xl:text-base text-center sm:text-left max-w-[620px]">
            Finally, we will upload full-resolution images of the NFTs to IPFS.
            This will allow token holders to download full-res images and
            transform their NFTs into fantastic pieces of wall art. Some images
            are over 500 megapixels, allowing for large-scale prints in high
            resolution.
          </p>
        </div>
      </div>
    </div>
  )
}

const ImageWithMask = ({ src, className }) => (
  <div className="flex items-center">
    <div className="flex-shrink-0 rounded-full border-4 border-primary flex items-center justify-center w-[160px] h-[160px] sm:w-[140px] sm:h-[140px] overflow-hidden bg-[#581759] bg-opacity-[.34]">
      <img src={src} className={className} />
    </div>

    <img src={pointerRight} className="hidden sm:block" />
  </div>
)
