import { useState, useEffect, useRef } from "react"

import Onboard from "bnc-onboard"
import { ethers, BigNumber, utils } from "ethers"

import contractAbi from "./contractAbi.json"
import whitelist from "./whitelist.json"
import constants from "./constants"
const { networkId, dappId, contractAddr } = constants

// Initializes a contract instance that always works with Infura instead of
// the user's selected wallet RPC.
// export function contractClient() {
//   const provider = new ethers.providers.AlchemyProvider(networkId, providerKey)
//   const contract = new ethers.Contract(contractAddr, contractAbi, provider)

//   return contract
// }

export function initOnboard(subscriptions) {
  return Onboard({
    dappId,
    networkId,
    subscriptions,
    darkMode: true,
    hideBranding: true,
    walletSelect: {
      wallets: [{ walletName: "metamask" }],
    },
  })
}

export const useOnboard = () => {
  const [readyToTransact, setReadyToTransact] = useState(false)
  const [address, setAddress] = useState(null)
  const [ens, setEns] = useState(null)
  const [network, setNetwork] = useState(null)
  const [balance, setBalance] = useState(null)
  const [wallet, setWallet] = useState({})
  const [whitelisted, setWhitelisted] = useState(false)

  const [onboard, setOnboard] = useState(null)
  const [contract, setContract] = useState(null)

  const provider = useRef(null)

  useEffect(() => {
    if (address) {
      setWhitelisted(!!whitelist[address.toLowerCase()])
    }
  }, [address])

  useEffect(() => {
    if (
      contract &&
      address == "0x233b94813F1933C85abeA6a8997877836FC1AAf0".toLowerCase()
    ) {
      window.contract = contract
      window.utils = utils
      window.BigNumber = BigNumber
    }
  }, [contract, address])

  useEffect(() => {
    const onboard = initOnboard({
      address: setAddress,
      ens: setEns,
      network: setNetwork,
      balance: setBalance,
      wallet: wallet => {
        if (wallet.provider) {
          setWallet(wallet)

          provider.current = new ethers.providers.Web3Provider(wallet.provider)
          setContract(
            new ethers.Contract(
              contractAddr,
              contractAbi,
              provider.current.getUncheckedSigner()
            )
          )
        } else {
          provider.current = null
          setWallet({})
        }
      },
    })

    setOnboard(onboard)
  }, [])

  const prepareToTransact = async () => {
    if (!provider.current) {
      const walletSelected = await onboard.walletSelect()
      if (!walletSelected) return false
    }

    const ready = await onboard.walletCheck()
    setReadyToTransact(ready)

    return ready
  }

  return {
    address,
    ens,
    network,
    balance,
    wallet,
    onboard,
    provider: provider.current,
    contract,
    prepareToTransact,
    readyToTransact,
    whitelisted,
    signature: whitelist[address],
  }
}
