import React from "react"
import classnames from "classnames"

import macImg from "../images/Mac.png"
import pcbImg from "../images/PCB.png"
import siliconImg from "../images/Silicon Chip.png"
import iitImg from "../images/IIT VPU d1.png"
import pointerDown from "../images/Pointer-20.png"
import pointerRight from "../images/Pointer.png"
import arrowDown from "../images/Arrow Copy 3.png"

export default function ChipProgression() {
  return (
    <div className="mt-20">
      <ImageMask
        className="bg-[#54592D] bg-opacity-[.35]"
        text="Computer"
        downArrow
        desktopDir="right"
      >
        <img src={macImg} className="w-[182px] xl:w-44" />
      </ImageMask>

      <ImageMask text={`Printed\nCircuit-Board`} downArrow desktopDir="left">
        <img src={pcbImg} className="w-full" />
      </ImageMask>

      <ImageMask
        className="bg-[#2D3A59] bg-opacity-[.35]"
        text="Silicon Chip"
        downArrow
        desktopDir="right"
      >
        <img src={siliconImg} className="w-[138px] xl:w-28" />
      </ImageMask>

      <ImageMask text="Chip Art" desktopDir="left">
        <img src={iitImg} className="w-full" />
      </ImageMask>
    </div>
  )
}

const ImageMask = ({ children, className, text, downArrow, desktopDir }) => (
  <div>
    {/* Mobile */}
    <div className="sm:hidden">
      <div
        className={classnames(
          "rounded-full border-4 border-primary flex items-center justify-center w-60 h-60 mx-auto overflow-hidden",
          className
        )}
      >
        {children}
      </div>

      <img src={pointerDown} className="mx-auto" />

      <p className="font-bold text-lg text-primary uppercase tracking-widest mt-4 text-center whitespace-pre-line">
        {text}
      </p>

      {downArrow && <img src={arrowDown} className="mx-auto my-14" />}
    </div>

    {/* Desktop */}
    <div className="hidden sm:block">
      <div
        className={classnames("flex items-center", {
          "xl:flex-row-reverse": desktopDir === "left",
        })}
      >
        <div className="xl:flex-1" />

        <div>
          <div
            className={classnames(
              "rounded-full border-4 border-primary flex items-center justify-center w-60 h-60 xl:w-60 xl:h-60 overflow-hidden",
              className
            )}
          >
            {children}
          </div>
        </div>

        <div
          className={classnames("flex-1 flex items-center justify-end", {
            "flex-row-reverse": desktopDir === "right",
            "flex-row-reverse xl:flex-row": desktopDir === "left",
          })}
        >
          <p
            className={classnames(
              "font-bold text-xl xl:text-2xl text-primary uppercase tracking-widest text-center whitespace-pre-line leading-none -mt-2",
              {
                "ml-8 xl:ml-0 xl:text-right xl:mr-8": desktopDir === "left",
                "ml-8": desktopDir === "right",
              }
            )}
          >
            {text}
          </p>

          <img
            src={pointerRight}
            className={classnames({ "xl:rotate-180": desktopDir === "left" })}
          />
        </div>
      </div>

      {downArrow && (
        <div className="w-60 xl:w-auto">
          <img src={arrowDown} className="mx-auto my-14" />
        </div>
      )}
    </div>
  </div>
)
