import React from "react"

import GradientHeading from "../components/GradientHeading"

import grid from "../images/grid@2x.png"
import artBird from "../images/art-bird.png"
import artWaldo from "../images/art-wheres_waldo.png"

import salt1 from "../images/hidden_inside/chip_art-05@2x_1.jpeg"
import salt2 from "../images/hidden_inside/chip_art-04@2x_1.jpeg"
import salt3 from "../images/hidden_inside/chip_art-03@2x_1.jpeg"
import salt4 from "../images/hidden_inside/chip_art-02@2x_1.jpeg"

export default function HiddenInside() {
  return (
    <div id="chipart" className="max-w-screen-xl mx-auto w-full">
      <div className="mt-16 relative md:w-2/3 xl:w-1/2">
        <div className="absolute top-0 left-0 -ml-44 -mt-12 sm:-mt-24 flex -z-10">
          <img src={grid} className="w-[306px] h-[306px]" />
          <img src={grid} className="w-[306px] h-[306px] -ml-px" />
          <img src={grid} className="w-[306px] h-[306px] -ml-px mt-[152px]" />
        </div>
        <GradientHeading>Hidden Inside the Chips</GradientHeading>
      </div>
      <div className="sm:flex">
        <div className="mt-8 relative">
          <div className="columns-1 lg:columns-2 gap-x-12 font-mono text-xs sm:text-sm xl:text-base text-primary">
            <p className="mb-4">
              When chips increased in size in the late ‘80s and ‘90s, some empty
              space was left on them. This presented the perfect opportunity for
              engineers to have some fun…
            </p>
            <p className="mb-4">
              Often without management knowing, chip designers set out to create
              all sorts of chip art utilizing unused space on integrated circuit
              boards. This was a cool way for them to both express their
              creativity and place their signature on their work.
            </p>
            <p>
              The chip art contains random objects or doodles generated by the
              chip designers, ranging from a ketchup bottle to a spaceship or
              even a cartoon character. Siliconinsider has found many
              interesting pieces, and most of them have never been released to
              the public.
            </p>
          </div>
        </div>
        <div className="mt-8 xl:ml-16 xl:-mt-48 ml-8 flex-shrink-0">
          <img src={artBird} className="w-[380px] lg:w-[420px]" />
        </div>
      </div>

      <div className="lg:flex justify-between mt-36 sm:mt-48">
        <div className="-mt-24">
          <img className="mx-auto w-[600px]" src={artWaldo} />
        </div>
        <div>
          <p className="font-mono text-xs sm:text-sm xl:text-base text-primary max-w-[338px] sm:ml-8 mt-12 sm:mt-20 lg:mt-0">
            Integrated circuit chip art is quite rare. Most companies in fact
            banned the practice in the late ‘90s because it delayed initial
            production efforts. Therefore, only about 4% of chips include this
            amazing art. In some rare cases, Siliconinsider has found as many as
            five pieces of artwork on a single chip.
          </p>
        </div>
      </div>

      <div className="mt-20 sm:mt-36">
        <h2 className="text-4xl sm:text-7xl lg:text-8xl font-bold text-primary uppercase leading-none mb-8">
          Invisible to
          <br />
          the naked eye
        </h2>
        <p className="font-mono text-xs sm:text-sm xl:text-base text-primary max-w-[420px] mt-6 sm:mt-12 lg:mt-0">
          Half the size of a grain of salt and invisible to the naked eye, these
          pieces of chip art found on the surface of computer chips are
          magnified from 200-500x.
        </p>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 max-w-screen-xl w-fit mt-20 mx-auto lg:mx-0">
          <div className="hidden lg:block">&nbsp;</div>
          <ChipCard image={salt1} />
          <ChipCard image={salt2} />
          <ChipCard image={salt3} />
          <ChipCard image={salt4} />
        </div>
      </div>
    </div>
  )
}

const ChipCard = ({ image }) => (
  <div className="flex flex-col bg-purple border border-primary w-[240px] sm:w-[260px] xl:w-[340px] 2xl:w-[360px] flex-shrink-0 sm:mx-0">
    <div className="flex items-center gap-4 py-2 px-4 border-b border-primary">
      <div className="flex-1 h-2 border-t border-b border-primary">&nbsp;</div>
      <div className="font-mono text-xs sm:text-sm text-primary">
        Chip Art Close-Up
      </div>
      <div className="flex-1 h-2 border-t border-b border-primary">&nbsp;</div>
    </div>

    <div className="p-6 h-full flex">
      <img src={image} className="w-full self-center" />
    </div>
  </div>
)
