import React, { useState, useEffect } from "react"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"

import arrow from "../images/Arrow Copy 3.png"

export default function ChipCarousel({ chips }) {
  const images = chips.map(chip => chip.image)

  const [lightboxOpen, setLightboxOpen] = useState(false)
  const [photoIndex, setPhotoIndex] = useState(0)

  const onItemClick = i => {
    setPhotoIndex(i)
    setLightboxOpen(true)
  }

  return (
    <div>
      {lightboxOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setLightboxOpen(false)}
          onMovePrevRequest={() => {
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }}
          onMoveNextRequest={() => {
            setPhotoIndex((photoIndex + 1) % images.length)
          }}
        />
      )}

      <div className="relative mt-32 -mx-7 sm:-mx-5 lg:-mx-32">
        <div className="overflow-x-auto w-auto flex flex-nowrap gap-8 md:gap-12 sm:gap-20">
          {chips.map((chip, i) => (
            <CarouselItem
              key={chip.title}
              item={chip}
              onClick={() => onItemClick(i)}
            />
          ))}
        </div>

        <div className="gradient-carousel hidden md:block absolute z-0 inset-y-0 right-0 w-40 xl:w-60 pointer-events-none"></div>
        <div className="absolute z-10 top-full mt-4 md:mt-0 md:top-1/2 md:-translate-y-1/2 right-0 mr-8">
          <img src={arrow} className="carousel-bounce-x" />
        </div>
      </div>
    </div>
  )
}

const CarouselItem = ({ item, onClick }) => (
  <div
    className="bg-purple border border-primary cursor-pointer w-[320px] md:w-[470px] xl:w-[520px] flex-shrink-0 first:ml-7 sm:first:ml-5 lg:first:ml-32 last:mr-7 sm:last:mr-5 lg:last:mr-32"
    onClick={onClick}
  >
    <div className="flex items-center gap-4 py-2 px-4 border-b border-primary">
      <div className="flex-1 h-2 border-t border-b border-primary">&nbsp;</div>
      <div className="font-mono text-xs sm:text-sm text-primary">
        {item.title}
      </div>
      <div className="flex-1 h-2 border-t border-b border-primary">&nbsp;</div>
    </div>

    <div className="p-6 sm:p-8">
      <img src={item.preview} className="w-full" />
      <p className="font-mono text-xs sm:text-sm text-primary mt-8 whitespace-pre-wrap">
        {item.description}
      </p>
    </div>
  </div>
)
