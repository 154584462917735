export default function getDisplayName(web3) {
  const { address, ens } = web3

  // if (ens && ens.name) {
  //   return ens.name
  // }

  if (address) {
    return `${address.substr(0, 4)}...${address.substr(-4)}`
  }

  return null
}
