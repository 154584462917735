import React from "react"
import Socials from "./Socials"
import grid from "../images/grid@2x.png"

export default function AboutArtOfScience() {
  return (
    <div className="max-w-screen-xl mx-auto w-full">
      <div className="relative max-w-[580px] lg:max-w-[660px]">
        <div className="absolute top-0 left-0 -ml-44 -mt-12 sm:-mt-24 flex -z-10">
          <img src={grid} className="w-[306px] h-[306px]" />
          <img
            src={grid}
            className="w-[306px] h-[306px] ml-[306px] mt-[152px]"
          />
        </div>
        <div className="gradient-medium-blue opacity-[.27] absolute top-0 left-0 -z-20 w-[1000px] h-[1000px] ml-[-334px] mt-[-250px]" />
        <div className="gradient-big-green opacity-[.14] absolute top-0 left-0 -z-20 w-[1852px] h-[1852px] ml-[-237px] mt-[-750px]" />

        <h2 className="text-4xl sm:text-7xl lg:text-8xl font-bold text-primary uppercase leading-none mb-8">
          About The Art of Science
        </h2>
      </div>

      <div className="sm:flex mt-4 sm:mt-20">
        <div className="flex-1 font-mono text-xs sm:text-sm xl:text-base text-primary">
          <div className="max-w-[600]px">
            <p className="mb-4">
              Art is all around us, but nature's glory isn’t always visible to
              the human eye. This is exactly why Art of Science challenges
              scientists, nature photographers, researchers, and inventors to
              share their unique views of the world.
            </p>
            <p>
              Some of this artwork has never been available to the public but
              can help improve the world and how we view science. With respect
              to the creators themselves, some have won international
              competitions and prizes, and their spectacular work has graced
              covers of scientific magazines. You can now help preserve and
              support scientific progress by minting one of 2,750 unique NFTs.
            </p>
          </div>
        </div>
        <div className="flex-1 self-end sm:ml-48 mt-12 sm:mt-0">
          <div className="mb-8">
            <Socials />
          </div>
        </div>
      </div>
    </div>
  )
}
