import React, { useState,useEffect } from "react"
import { Link } from "gatsby"

import Seo from "../components/seo"
import Header from "../components/Header"
import ChipCarousel from "../components/ChipCarousel"
import FancyDivider from "../components/FancyDivider"
import GradientHeading from "../components/GradientHeading"
import SectionDescription from "../components/SectionDescription"
import ChipProgression from "../components/ChipProgression"
import NFTCollectionBreaker from "../components/NFTCollectionBreaker"
import Roadmap from "../components/Roadmap"
import AboutArtOfScience from "../components/AboutArtOfScience"
import AboutTheTeam from "../components/AboutTheTeam"

import { CAROUSEL_1, CAROUSEL_2 } from "../lib/carousels"

import heroImage from "../images/AMD K5 SSA5 PR100.jpg"
import grid from "../images/grid@2x.png"

import disappearingChip from "../images/Disappearing Chip.png"

import { useOnboard } from "../lib/services"
import MintModule from "../components/MintModule"
import HiddenInside from "../components/HiddenInside"

const IndexPage = () => {
  const web3 = useOnboard()
  const [assets,setAssets] = useState([])
  const {address,prepareToTransact} = web3
  let temp = []

  //Fecth Data
  useEffect(() =>{
    async  function  getTokens (){
      //const url =`https://testnets-api.opensea.io/api/v1/assets?collection=unidentified-contract-o9ezgxc7al&owner=${address}`;
      const url =`https://api.opensea.io/api/v1/assets?collection=chipart&owner=${address}`;
      //const url =`https://api.opensea.io/api/v1/assets?collection=chipart&owner=0xd28B3000f6fDA2cFe5e083db2e793C3A7eeb27dF`;
      var myHeaders = {
        'Content-Type': 'application/json'
      }
     fetch(url, {  
         method: 'GET',
         mode: 'cors',
         cache: 'no-store',
         headers: myHeaders      
        })
        .then(response => 
          response.json()
        )
        .then(data => {
        if (data.assets.length > 0 ) {
          data.assets.forEach((item) =>{
            const token = parseInt(item.token_id);
            let cid;
            if (token >= 1 && token <= 100) {
              cid = "QmbKDSsAyP3b2Gk9g9JjncUwZLAZEFYLamLnTFpAPVAfSb";
            } else if (token >= 101 && token <= 200) {
              cid = "QmQowBeU5apNjsUUjhDh2mJhbzVvNnZogQBVJRxoZ8iaBQ";
            } else if (token >= 201 && token <= 300) {
              cid = "QmPM6s3nQQd8HLvMRpYiEwDev2KJtvU6AAdW9k62vwx3tL";
            } else if (token >= 301 && token <= 400) {
              cid = "QmcK3LFctU3yVtZMj7Vi4a2hbDtgZ6wZh1axMSShzSCDVM";
            } else if (token >= 401 && token <= 500) {
              cid = "QmNWSUtKkmKcVUgF6G9sr9qV7JRPqpU7Z1WHcJpKiGwtuV";
            } else if (token >= 501 && token <= 600) {
              cid = "QmWusBsU6U1eGHnGxRy3BQQUrkT1znCwqA8zx3ZnJ99n5N";
            } else if (token >= 601 && token <= 700) {
              cid = "QmVBuUseiCCrEVDAyYZ9HGREmSyNwGgTnzfi7ARBHtRhQc";
            } else if (token >= 701 && token <= 800) {
              cid = "QmPYg7Fgx8szgs6CX7ATYNP18RrFFFq5Lb3PDUhqTgpudE";
            } else {
              cid = "QmPo8NzkCcAGFU6yifAMR8RLHDE6GbHspDuodSsHiGXsR9";
            }

             let tokenUrl = item.image_original_url.replace(
                  "ipfs://QmPo8NzkCcAGFU6yifAMR8RLHDE6GbHspDuodSsHiGXsR9",
                  `https://chipart.mypinata.cloud/ipfs/${cid}`
                ) 
              temp.push({id:item.token_id, url: tokenUrl})
          })
        }
        setAssets(temp)
      
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
    if (address) {
      getTokens()
    }
    
  },[address])

  return (
    <div className="relative overflow-x-hidden">
      <Seo title="Art of Science" image={""} />

      <div className="max-w-[2048px] mx-auto px-4 sm:px-8 lg:px-16">
        <Header web3={web3} />
      </div>

      <div className="px-7 sm:px-5 lg:px-32">
        <div className="max-w-screen-xl mx-auto w-full">
          <div className="gradient-big-pink absolute top-0 left-0 -ml-[448px] -mt-[293px] -z-10 opacity-[.18] w-[1676px] h-[1676px]" />
          <div className="gradient-big-blue absolute top-0 left-0 ml-10 mt-20 -z-10 opacity-[.79] w-[1330px] h-[1330px]" />

          <div className="w-auto -mx-7 sm:mx-auto mt-16 sm:mt-40">
            <img className="w-full" src={heroImage} />

            <p className="font-mono text-sm sm:text-base text-primary text-right mt-6 mr-6 sm:mr-0 leading-tight">
              Pre-mint: March 9, 2022 5:00pm EST
              <br />
              Public mint: March 10, 2022 5:00pm EST
              <br />
              Collection: 2,750 NFT
              <br />
              Mint price: ETH 0.18
            </p>
          </div>

          {address && assets.length !== 0 ? (     
          <div className="flex flex-col mt-12 mb-8" > <hr></hr> <br></br>
          <GradientHeading>My Minted ChipArts</GradientHeading>
         
            {/* <h2 className="text-white text-6xl">My Tokens</h2> */}
            <div className="flex gap-8 mt-8 mb-8  flex-wrap">
            {assets.map((item) =>(
            <NFTToken
                key={item.id}
                name={item.id}
                image={item.url}
            >
            </NFTToken>
            ))}
            </div>
          </div>
           ) : address && assets.length === 0 ?(
            <div className="flex flex-col mt-12 mb-8" > <hr></hr> <br></br>

             <GradientHeading>My Minted ChipArts</GradientHeading>
          
             <div className="flex flex-col  items-start  mt-8 mb-8">
                <p className=" text-primary ">
                 You have to mint to be able to download your ChipArt!
                 </p><br/>
             </div>
           </div>
           ):
           
           (
            <div className="flex flex-col mt-12 mb-8" > <hr></hr> <br></br>

             <h2 className="text-2xl sm:text-5xl lg:text-6xl font-bold text-primary uppercase leading-none mb-8">My Minted ChipArts</h2>
           
              <div className="flex flex-col  items-start  mt-8 mb-8">
                <span className=" text-primary ">
                  Please connect your wallet in order to download your minted ChipArts
                  </span><br/>
              <button
              onClick={prepareToTransact}
              className=" xl:inline-flex font-medium text-primary xl:text-lg uppercase  hover:opacity-80 tracking-[.3125em]"
            >
              Connect
            </button>
              </div>
            </div>
           )
           
           }  
           



          <div className="mt-16 relative lg:w-2/3 xl:w-1/2">
            <GradientHeading>Hidden World of Art</GradientHeading>

            <SectionDescription>
              <p className="mb-4">
                For the past 25 years, Dr. Antoine Bercovici, a.k.a.
                Siliconinsider, has made it his life's work to dig deep into
                computer chips to see what's hidden inside; and the results are
                truly mind-blowing.
              </p>
              <p className="mb-4">
                Siliconinsider, a researcher by profession, specializes in
                microscope photography. By dismantling chips often only a few
                millimeters in size, he reveals their true beauty under a
                microscope. Some chips are so detailed and tiny that it's
                necessary to take hundreds of photos per die, which are then
                digitally stitched together to form one single large image. It
                often takes days to prepare one detailed image using this
                process, but the results are stunning.
              </p>
            </SectionDescription>
          </div>

          <MintModule assets={assets} web3={web3} />
     
     

        </div>

        <ChipCarousel chips={CAROUSEL_1} />

        <div className="max-w-screen-xl mx-auto w-full mt-20">
          <p className="font-mono text-xs sm:text-sm xl:text-base text-primary lg:w-2/3 xl:w-1/2 mt-12 sm:mt-20 lg:mt-0">
            The bright colors, level of detail, and interesting patterns make
            each image a beautiful piece of art. Some images make you think of
            contemporary art, while others have an industrial vibe or vintage
            edge to them. Each of the 2,750 NFTs is unique and tells its own
            story.
          </p>
        </div>

        <FancyDivider className="-mt-24 sm:-mt-12 mb-24 sm:mb-36" />

        <HiddenInside />

        <div className="relative">
          <div className="gradient-big-pink absolute top-0 left-0 -ml-[55px] -mt-[500px] -z-10 w-[1644px] h-[1644px] opacity-[.14]" />
        </div>

        <FancyDivider className="-mt-24 sm:-mt-12 mb-24 sm:mb-36" />

        <ChipProgression />

        <FancyDivider className="-mt-24 sm:-mt-12 mb-24 sm:mb-36" />

        <div id="about" className="max-w-screen-xl mx-auto w-full">
          <div className="mt-16 relative md:w-2/3 lg:w-auto max-w-[1042px]">
            <div className="absolute top-0 left-0 -mt-6 sm:mt-12 -ml-40 flex -z-10">
              <img src={grid} className="w-[306px] h-[306px]" />
              <img src={grid} className="w-[306px] h-[306px] -ml-px" />
              <img src={grid} className="w-[306px] h-[306px] -ml-px" />
            </div>
            <GradientHeading>About the Project</GradientHeading>
          </div>

          <div className="sm:flex items-center mt-4 sm:mt-20">
            <div className="max-w-[600px] font-mono text-xs sm:text-sm xl:text-base text-primary">
              <p className="mb-4">
                Many milestones have shaken up humanity over time, such as the
                Stone Age, Bronze Age, Iron Age, etc. Today could perhaps be
                known as the "Silicon Age,” wherein we have learned to craft one
                of the most common elements found on the surface of the Earth
                into a highly complex technological achievement.
              </p>
              <p className="mb-4">
                Billions of silicon chips are manufactured every month, shaping
                how we communicate, work, trade, and entertain ourselves: from
                deep within your pocket inside your mobile phone and credit card
                to outside our solar system aboard the NASA Voyager 1 probe. The
                insatiable race toward improved performance means that devices
                quickly become obsolete.
              </p>
              <p className="mb-4">
                Old hardware is scrapped and recycled, making old chips
                increasingly harder to find and erasing the history of our
                modern society written in silicon. Thankfully, this NFT project
                will build and preserve a historic database for fans of computer
                chip history—and their hidden artwork—on IPFS.
              </p>
              <p className="mb-4">
                Siliconinsider has amassed one of the world's largest
                collections of computer chips from gaming consoles, personal
                computers, crypto mining servers, and other electronic devices.
                It has taken him five years to photograph each and every one,
                with his efforts resulting in a stunning photo collection.
              </p>
              <p>
                Now, Siliconinsider is teaming up with Art of Science to release
                this compelling database into an NFT collection. The drop will
                contain 2,750 NTFs minted on the Ethereum blockchain as ERC-721
                tokens. Each holder will be assigned a unique image photographed
                by a digital microscope, with some containing chip art hidden
                inside.
              </p>
            </div>
            <div className="flex-1 sm:ml-20 mt-14 sm:mt-0 relative">
              <div className="gradient-medium-blue-2 opacity-[.33] absolute top-0 left-0 -mt-[300px] w-[766px] h-[766px]" />
              <img src={disappearingChip} className="w-[480px] mx-auto" />
            </div>
          </div>
        </div>

        <ChipCarousel chips={CAROUSEL_2} />

        <FancyDivider className="-mt-24 sm:-mt-12 mb-24 sm:mb-36" />

        <Roadmap />

        <FancyDivider className="-mt-24 sm:-mt-12 mb-24 sm:mb-36" />

        <AboutArtOfScience />

        <FancyDivider className="-mt-24 sm:-mt-12 mb-24 sm:mb-36" />

        <NFTCollectionBreaker />

        <FancyDivider className="-mt-24 sm:-mt-12 mb-24 sm:mb-36" />

        <AboutTheTeam />

        <FancyDivider className="-mt-24 sm:-mt-12 mb-24 sm:mb-12" />

        <p className="font-mono text-xs sm:text-sm lg:text-lg text-center text-primary mb-2">
          Copyright 2022 — All Rights Reserved — Art of Science
        </p>
        <p className="font-mono text-xs sm:text-sm lg:text-lg text-center text-primary mb-16">
          <Link to="/terms" className="underline">
            Terms & Conditions
          </Link>
        </p>
      </div>
    </div>
  )
}

export default IndexPage

//Download Function
export const download = (url, name) => {
  fetch(url, {
    method: "GET",
    headers: {},
  })
    .then((response) => {
      response.arrayBuffer().then(function (buffer) {
        const url = window.URL.createObjectURL(new Blob([buffer]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", `${name}.jpg`) //or any other extension
        document.body.appendChild(link)
        link.click()
      })
    })
    .catch((err) => {
      console.log(err)
    })
}

export const NFTToken = ({ name, image}) => (
  <div className="flex flex-col bg-purple border border-primary  max-w-[280px]">
    <div className="flex items-center gap-2 py-2 px-4 border-b border-primary">
      <div className="flex-1 h-2 border-t border-b border-primary">&nbsp;</div>
      <div className="font-mono text-sm text-primary"># {name}</div>
      <div className="flex-1 h-2 border-t border-b border-primary">&nbsp;</div>
    </div>

    <div className="flex-1">
      <div className="h-80 w-auto">
         <img src={image} className="w-full h-full border-b border-primary" />
      </div>
    </div>

    
      <div className="flex items-center p-4">
         <button onClick={() => download(image, name)}
                className="font-mono text-xs sm:text-sm text-primary underline"
         >
            Download
          </button>
       
        {/* <a
          href={`https://twitter.com/`}
          className="font-mono text-xs sm:text-sm text-primary underline"
          target="_blank"
        >
         Download
        </a> */}
      </div>
 
  </div>
)
